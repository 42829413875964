import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner, { WrapperButton } from "../components/elements/Banner"
import Expertises from "../components/Home/Expertises"
import AgenceBlocs from "../components/Agence"
import Certifications from "../components/Home/Certifications"
import Contact from "../components/Home/Contact"
import Join from "../components/Agence/join"
import Faq from "../components/elements/Faq"
import Button from "../components/elements/Button"

const NosMetiers = ({ data, location }) => {
  const { nos_metiers, bloc_certifications, bloc_contact } = data

  return (
    <Layout
      location={location.pathname}
      crumbLabel="Nos Expertises"
      locationGTM={location.href}
    >
      <SEO
        title={nos_metiers.acf.title}
        description={nos_metiers.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <Banner
        imageBanner={
          nos_metiers.acf.image_banniere_new.localFile.childImageSharp.fluid
        }
        title={nos_metiers.acf.titre_banniere}
        description={nos_metiers.acf.texte_banniere}
        hashtag={nos_metiers.acf.hashtag}
        anchor="expertises"
      >
        <WrapperButton>
          <Button
            as="link"
            title="Contactez-nous"
            to="/contact"
            colors="borderSecondaryHoverSecondary"
          />
        </WrapperButton>
      </Banner>
      <Expertises
        title={nos_metiers.acf.titre_expertise}
        text={nos_metiers.acf.texte_expertise}
        items={nos_metiers.acf.expertises}
      />

      <AgenceBlocs
        image={nos_metiers.acf.image_1.localFile.childImageSharp.fluid}
        title={nos_metiers.acf.titre_1}
        text={nos_metiers.acf.texte_1}
        flexImage="1 0 50%"
        flexText="1 0 50%"
        backgroundText="greyLighter"
        orderImage="2"
        ordertext="1"
        orderImageMobile="2"
        ordertextMobile="1"
        isSynergyImage
      />
      <Certifications
        title={bloc_certifications.acf.titre_certification}
        text={bloc_certifications.acf.texte_certification}
        items={bloc_certifications.acf.certifications}
      />
      <Join
        title={nos_metiers.acf.titre_2}
        description={nos_metiers.acf.texte_2}
        imageBanner={nos_metiers.acf.image_2.localFile.childImageSharp.fluid}
        imageHeight="430px"
        imageMobileHeight="426px"
      />
      <Faq
        title={nos_metiers.acf.titre_faq}
        text={nos_metiers.acf.texte_faq}
        themes={nos_metiers.acf.themes_faq}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default NosMetiers

export const query = graphql`
  query {
    nos_metiers: wordpressPage(
      id: { eq: "658f722b-6703-5789-86a8-e64b0b217383" }
    ) {
      acf {
        title
        meta_description
        titre_banniere
        texte_banniere
        hashtag
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_expertise
        texte_expertise
        expertises {
          expertise {
            image_expertise {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            lien_expertise
            texte_expertise
            titre_expertise
            logo_expertise {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    src
                  }
                }
              }
            }
          }
        }
        titre_1
        texte_1
        image_1 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_2
        texte_2
        image_2 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_faq
        texte_faq
        themes_faq {
          titre_theme_faq
          questions_faq {
            question_faq
            reponse_faq
            lien_faq
            texte_bouton_faq
          }
        }
      }
    }

    bloc_certifications: wordpressAcfBlocs(
      id: { eq: "b093d1ee-95cf-54db-b63c-5f4803c934fb" }
    ) {
      acf {
        titre_certification
        texte_certification
        certifications {
          logo_certification {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
